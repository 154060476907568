import React from 'react'
import './loading.css'

const Loading = () => {
  return (
    <div className='load-container container'>
        <div className="loader"></div>
    </div>
  )
}

export default Loading