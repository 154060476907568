
import Loading from "components/Loading/loading";
import Error from "pages/Error/error";

import { Kategori } from "pages/Kategori/kategori";
import React, { Suspense } from "react";
import { Routes, Route } from "react-router-dom";

// Lazy-loaded
const Home = React.lazy(() => import("pages/Home/Home"));


function AppRouter() {
  return (
    <Routes>
      <Route
        exact
        path="/"
        element={
          <Suspense fallback={<Loading />}>
            <Home />
          </Suspense>
        }
      />
      <Route
        path="/category/:name"
        element={
          <Suspense fallback={<Loading />}>
            <Kategori />
          </Suspense>
        }
      />
       
     
       <Route path="*" element={<Error />} /> 
    </Routes>
  );
}
export default AppRouter;
