import React from "react";
import { LazyLoadImage } from "react-lazy-load-image-component";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/effect-coverflow";
import "swiper/css/pagination";
// import required modules
import { EffectCoverflow, Pagination } from "swiper";

const CategorySlide = ({ props }) => {

  let n;
  if(props==="/images/category/services-2/img"){
    n=7
  }
  else if(props==="/images/category/services-3/img"){
    n=12
  }
  else if(props==="/images/category/services-4/img"){
    n=15
  }
  const arr = [];
  for (let i = 1; i < n; i++) {
    arr.push(i);
  }
  
  return (
    <Swiper
      effect={"coverflow"}
      grabCursor={true}
      centeredSlides={true}
      slidesPerView={"auto"}
      coverflowEffect={{
        rotate: 50,
        stretch: 0,
        depth: 100,
        modifier: 1,
        slideShadows: true,
      }}
      pagination={true}
      modules={[EffectCoverflow, Pagination]}
      className="mySwiper"
    >
    
        {arr.map((item) => (
                <SwiperSlide>
                  <LazyLoadImage src={`${props}-${item}.jpg`}/>
                </SwiperSlide>
        ))}
     
    </Swiper>
  );
};

export default CategorySlide;
