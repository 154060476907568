import React,{useContext,useEffect} from 'react'
import { ServicesContext } from 'context/services'
import CategorySlide from 'components/Card/CategorySlide';
import { useParams } from "react-router-dom";
import Aos from 'aos'
import "aos/dist/aos.css"

import './kategori.css'
import NavbarCategory from 'components/Navbar/navbar-category';


export const Kategori = () => {
  const { name } = useParams();

  const services= useContext(ServicesContext)
  const numParams=name.split("-")[1]
  const filterService=services.filter((item) =>item.id=== numParams)[0]

  useEffect(()=>{
    Aos.init({duration:2000})
  },[])

  return (
    <div>
      <NavbarCategory />
      <div className='container'>
        
          <div className='populer'>
            <div className='title' style={{backgroundImage:`url(${filterService.vitrin})`}}>
              <h1>{filterService.title}</h1> 
            </div>
            <div className='desc' data-aos="fade-up">
            <h2><span>İzmir</span> <span>Düğün</span> <span>Hikayem</span></h2>
            <p>{filterService.desc}</p>
            <a className="about-insta" target="_blank"  href="https://instagram.com/izmirdugunhikayemm?igshid=ZDdkNTZiNTM="> İnstagramdan Bizi İnceleyebilirsiniz. <br/>  <i className="fab fa-instagram" ></i></a>
          </div>
            {
              filterService.imgsUrl &&(
                <CategorySlide   props={filterService.imgsUrl} />
              )
            }
            
          </div>
          
         
      </div>
      <div className='videos'>
        <div className='container'>
          <div className='row'>
            {
              filterService.videos &&(
                filterService.videos.map(video=>(
                  <div className='col-xs-12 col-md-6 py-4' key={video}>
                    <div className="ratio ratio-16x9">
                      <iframe src={video} title="YouTube video" allowFullScreen data-aos="fade-up"  ></iframe>
                    </div>
                  </div>
                ))
              )
            }
            
          </div>
        </div>
      </div>
    </div>
  )
}
