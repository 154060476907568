import React from 'react'
import { Link } from 'react-router-dom'
import Aos from 'aos'
import "aos/dist/aos.css"

import './error.css'
import { useEffect } from 'react'

const Error = () => {

  useEffect(() => {
    Aos.init({ duration: 3000 });
  }, []);

  return (
    <div className='error'>
        <div className='container'>
          <img src='/images/home/error.png'data-aos="fade-down"  />
                <Link to="/"><i className="fa-solid fa-house pe-2"></i>Ana Sayfaya Dön</Link>
        </div>
    </div>
  )
}

export default Error