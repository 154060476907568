import React from "react";
import { BrowserRouter as Router } from "react-router-dom";
import "./App.css";
import AppRouter from "routes";
import Footer from "components/Footer/Footer";
import ServicesPro from "context/services";
import ScrollToTop from "components/ScrollTop/Scrolltop";
import PaketPro from "context/paketler";

function App() {

  
  

  return (
    <Router>
      <div className="App">
        <ScrollToTop>
         
          <ServicesPro>
            <PaketPro>
               <AppRouter />
            </PaketPro>
          </ServicesPro>
          <Footer />
        </ScrollToTop>
      </div>
    </Router>
  );
}

export default App;
