import React, { createContext} from "react";

export const ServicesContext=createContext();

const ServicesPro = ({children}) => {
    let services=[
        {
            id:"1",
            title:"Düğün Hikayesi ",
            desc:"Düğün fotoğrafçılığında yeni bir nesil düğün klibi, günümüz düğün fotoğraçılığında dijital platformların içeriğinde videoların etkin kullanımından ötürü düğün klibine( Wedding story) rağbet üst seviyededir.Düğün gününe hazırlık aşamalarından başlayarak tüm gün boyunca ( Before Party - Kuaför - Bride Party- DRONE - Gelin Alma - Nikah - Düğün Salonu) sizlerle birlikte bu mutlu anlarınızı videoya kaydına almaktayız. Estetik ve eğlence dolu mutlu anlarınızı 5 dk'lık video klibi ile ölümsüzleştirmekteyiz.",
            homeImgUrl:"/images/home/services",
            videos:["https://www.youtube.com/embed/z_qKqCXcUXg","https://www.youtube.com/embed/QRETW0GdTxM","https://www.youtube.com/embed/GoiMV_3NHG0","https://www.youtube.com/embed/w6BilrxVYi8","https://www.youtube.com/embed/j-JIxKVl8fc","https://www.youtube.com/embed/AmJnUaCGifU"],
            vitrin:"/images/category/services-1/vitrin.jpg",

        },
        {
            id:"2",  
            title:"Reels ve Story",
            desc:"Tüm paketlerimizde bulunan Reels ve Story hizmetlerimizle düğün günü içeriklerinizi instagramda sayfamızdan inceleyebilirsiniz. ",
            homeImgUrl:"/images/home/services",
            imgsUrl:"/images/category/services-2/img",
            vitrin:"/images/category/services-2/vitrin.jpg",
        },
        {
            id:"3",
            title:"Dış Çekim ",
            desc:"Platolarda ve özel mekanlarda yaptığımız eğlenceli ve keyif dolu dış çekim paktlerimizden sizlerde faydalanın. Belirlenen mekanlarda gün boyu saat ve poz sınırı olmadan çekim yapmaktayız. Dekor, doğa, mimari ve sunset konseptlerimiz bir arada bulunmaktadır. Çektiğimiz fotoğrafları photoshop sürecinden geçirdikten sonra yüksek kalitede albümler ve tablolar olarak sizlerle paylaşmaktayız.",
            homeImgUrl:"/images/home/services",
            imgsUrl:"/images/category/services-3/img",
            vitrin:"/images/category/services-3/vitrin.jpg",
            videos:["https://www.youtube.com/embed/FnSKaIp6rSk","https://www.youtube.com/embed/Qym4LdpxLw0","https://www.youtube.com/embed/zy4AvFNW6o0","https://www.youtube.com/embed/iwxyvfcMfeQ"  ]
        },
        {
            id:"4",
            title:"Takip Fotoğrafları",
            desc:"Bu en mutlu gününüzde tüm anlarınızı ( Before Party - Kuaför - Bride Party - Gelin Alma - Düğün Salonu) ölümsüzleştirmek için takip fotoğraf paketlerimizi inceleyebilirsiniz. ",
            homeImgUrl:"/images/home/services",
            imgsUrl:"/images/category/services-4/img",
            vitrin:"/images/category/services-4/vitrin.jpg",
        },
    ]
  return (
    <ServicesContext.Provider value={services}>{ children }</ServicesContext.Provider>
  )
}

export default ServicesPro