import React from "react";
import "./Navbar.css";


const NavbarCategory = () => {



  return (
    <div className="navbar-fixed">
      <nav className="navbar navbar-expand-lg bg-light">
        <div className="container-fluid">
         <div className="mobile-navbar">
         <button
            className="navbar-toggler"
            type="button"
            data-bs-toggle="collapse"
            data-bs-target="#navbarScroll"
            aria-controls="navbarScroll"
            aria-expanded="false"
            aria-label="Toggle navigation"
          >
            <span className="navbar-toggler-icon"></span>
          </button>
          <div className=" tel d-lg-none">
            <a href="tel:+90553 211 3820">
            <div className="animation-phone">
                    <div className="first"><div>Telefon</div></div>
                    <div className="second"><div> İzmir Düğün Hikayem</div></div>
                    <div className="third"><div>0553 211 3820</div></div>
                  </div>
            </a>
          </div>
         </div>
          <div className="collapse navbar-collapse" id="navbarScroll">
            <ul className="navbar-nav me-auto my-2 my-lg-0 navbar-nav-scroll">
              <li className="nav-item">
                <a className="nav-link active" aria-current="page" href="/">
                  Ana Sayfa
                </a>
              </li>
              <li className="ms-auto me-5 d-none d-lg-block">
                <a href="tel:+90553 211 3820">
                  <div className="animation-phone">
                    <div className="first"><div>Telefon</div></div>
                    <div className="second"><div> İzmir Düğün Hikayem</div></div>
                    <div className="third"><div>0553 211 3820</div></div>
                  </div>
                </a>
              </li>
            </ul>
          </div>
        </div>
      </nav>
    </div>
  );
};

export default NavbarCategory;
