import React, { createContext} from "react";

export const PaketContext=createContext();

const PaketPro = ({children}) => {
    let Paketler=[
        {
            id:"1",
            desc:"...",
            imgsUrl:"/images/paketler/paket-1.jpg",

        },
        {
            id:"2",  
            desc:"...",
            imgsUrl:"/images/paketler/paket-2.jpg",
         },
        {
            id:"3",
            desc:"...",
            imgsUrl:"/images/paketler/paket-3.jpg",
 
        },
        
       
    ]
  return (
    <PaketContext.Provider value={Paketler}>{ children }</PaketContext.Provider>
  )
}

export default PaketPro