import React,{useEffect} from 'react'
import './Footer.css'
import { Link } from 'react-router-dom';
import Aos from 'aos'
import "aos/dist/aos.css"

const Footer = () => {

   useEffect(()=>{
    Aos.init({duration:2000})
  },[])

    const scrollWin = () => {
        window.scrollTo(0, 0);
      };
  return (
    <div className='footer'>
         <div className="scroll-top">
          <a className='insta-footer' target="_blank" href="https://instagram.com/izmirdugunhikayemm?igshid=ZDdkNTZiNTM=">
            <i className="fab fa-instagram" ></i>
          </a>
          <a className="wp-m" target="_blank" href="https://api.whatsapp.com/send?phone=+905532113820&text=Merhaba bilgi almak istiyorum." >
                <i className="fab fa-whatsapp"></i>
            </a>
            <a className='tel-footer' href="tel:+90553 211 3820" >
              <i className="fa-solid fa-phone"></i>
            </a>
           
            <div
              role="button"
              className="text-center"
              onClick={scrollWin} >
             <i className="fa-solid fa-chevron-up"></i>
            </div>
          </div>
          <div className='alt-footer'>
            <div className='container'>
              <div className='row'>
                <div className='col-12 col-md-3'><img src="images/home/just.gif" data-bottom-top="@src:images/home/just.gif" height="150" alt="düğün" ></img></div>
                <div className='col-12 col-md-4'data-aos="fade-down" >
                  <h3>Hakkımızda</h3>
                    <p>'Hayatınızın En Güzel Anlarını Ölümsüzleştirme' mottosuyla kurulan İzmir Düğün Hikayem, çiftlerin hayallerine ilham olmak için  yeni nesil, profesyonel ve
                       hayal gücüyle süslenmiş çekimlerimiz ile sizlerin mutluluğunu paylaşıyoruz. Modern ve yenilikçi düğün fotoğrafçılığını temsil ediyoruz ve bugüne dek yüzlerce
                       yurtiçi ve yurtdışı çiftin mutluluk anlarını, çektiğimiz fotoğraf ve videolarla sonsuzlaştırıyoruz. 
                    </p>
                </div>
                <div className='col-12 col-md-3' data-aos="fade-up">
                  <h3>İletişim Ağlarımız</h3>
                  <ul>
                    <li><a target="_blank" href="https://instagram.com/izmirdugunhikayemm?igshid=ZDdkNTZiNTM="><i className="fab fa-instagram" ></i> Instagram</a></li>
                    <li><a target="_blank" href='https://youtube.com/@tozproduksiyon4312'><i className="fab fa-youtube"></i> Youtube</a></li>
                    <li><a target="_blank" href="https://api.whatsapp.com/send?phone=+905532113820&text=Merhaba bilgi almak istiyorum."><i className="fab fa-whatsapp"></i> Whatsapp</a></li>
                    <li><a href="tel:+90553 211 3820" ><i className="fa-solid fa-phone"></i> Telefon</a></li>
                  </ul>
                </div>
                <div className='col-12 col-md-2' data-aos="fade-down">
                  <h3>Hizmetlerimiz</h3>
                  <ul>
                   <li><Link to={'category/DugunHikayesi-1'}>Düğün Hikayesi</Link></li> 
                   <li><Link to={'category/ReelsveStory-2'}>Reels ve Story</Link></li> 
                   <li><Link to={'category/DisCekim-3'}> Dış Çekim</Link></li> 
                   <li><Link to={'category/TakipFotograflari-4'}>Takip Fotoğrafları</Link></li> 
                  </ul>
                </div>
              </div>
            </div>
          </div>
    </div>
  )
}

export default Footer